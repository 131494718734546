
import { useState } from 'react';
import { totalSumofArray } from './js/GlobalScript';
import ContactFoodIcon from './images/contactFood.jpg'
import SVGIcons from './js/svgicons';
export default function Basket() {
    const [displaybasketTable, setbasketTable] = useState(true);
    const [displaychekoutform, setcheckoutform] = useState(false);
    const [displaythankyouMsg, setthankyouMsg] = useState(false);

    const backtoShopping = key => () => {
        localStorage.setItem('basketSelected', '')
        window.location.reload(true);;
    }
    function basketCheckout() {
        setbasketTable(false);
        setcheckoutform(true)
    }
    function submitOrder(event) {
        setcheckoutform(false);
        setthankyouMsg(true);
        localStorage.setItem('cartdata', '');
        localStorage.setItem('basketSelected', '');
        localStorage.setItem('foodData', '')

    }

    const resetCart = key => () => {
        localStorage.setItem('cartdata', '');
        localStorage.setItem('basketSelected', '');
        localStorage.setItem('foodData', '')
        window.location.reload(true);
    }

    let localfoodData = (localStorage.getItem('foodData')) ? JSON.parse(localStorage.getItem('foodData')) : []
    let totalPrice = [];
    let datalen = (localStorage.getItem('cartdata')) ? localStorage.getItem('cartdata').split(',') : [];



    return (
        <section id="basketSection" className="basket_section layout_padding3">
            <div className="container">
                <div className="row">
                    <a className="backtoShopping col-6 text-start" onClick={backtoShopping()}>Back to Shopping</a>
                    {!displaythankyouMsg && localfoodData && localfoodData.length > 0 &&
                        <a className="emptycart col-6 text-end" onClick={resetCart()} >Empty Cart</a>
                    }</div>
                <div className="heading_container w-100 heading_center mb-4">
                    {displaybasketTable ?
                        (<h2>
                            {datalen.length > 0 ? ' Review Your Basket' : 'Your Basket is Empty'}
                        </h2>)
                        : (displaythankyouMsg) ? '' : (<h2>Complete Your Order</h2>)

                    }

                </div>
                {!displaythankyouMsg && displaychekoutform &&
                    <section className='checkoutFormOption layout_padding3-top'>
                        <div className='container'>
                            <div className='row'>
                                <div className='checkoutContact mx-auto col-md-6 '>
                                    <div className='col-4 float-start p-0 '>
                                        <img src={ContactFoodIcon} alt='Contect us' />
                                    </div>
                                    <div className='col-6 float-start contactBasket'>
                                        <p>We are available to take orders on call as well</p>
                                        <p> <a href="tel:+44 7466404414">+44 7466404414</a></p>
                                    </div>
                                    <div className='col-md-2 float-start col-sm-12  OrPartition'>
                                        <h6 className='partitionText'>--OR--</h6>
                                        <span className='formdivider'></span></div>
                                </div>
                                <div className="checkoutForm col-md-6">
                                    <form >
                                    <div className="mb-3">
                                        <label htmlFor="fullname" className="form-label required">Full Name</label>
                                        <input type="text" className="form-control required" required name='fullname' id="fullname" />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="mobile" className="form-label">Mobile</label>
                                        <input type="number" className="form-control" required name='mobile' id="mobile" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                        <input type="email" className="form-control" required name='email' id="exampleFormControlInput1" placeholder="name@example.com" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <textarea className="form-control" name='address' required id="address" rows="3"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-danger float-right mt-3" >Submit Your Order</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>


                }
                {!displaythankyouMsg && displaybasketTable &&
                    <div className="basketData">
                        <ol className="list-group list-group-numbered">
                            {localfoodData && localfoodData.length > 0 &&
                                <div>
                                    {localfoodData.map(item => {
                                        let len = datalen.filter(x => parseInt(x) === parseInt(item.sku)).length;
                                        totalPrice.push(item.price * len)
                                        return (
                                            <li key={item.sku} className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold"><strong>{item.name}</strong></div>
                                                    <small>&pound;{item.price} x {len} </small>
                                                </div>
                                                <span className="h5 ">&pound;{item.price * len} </span>
                                            </li>
                                        );

                                    })}
                                    <li className="bg-cart list-group-item d-flex justify-content-between align-items-start">

                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold"><strong>Total</strong></div>
                                            <small>{datalen.length} items </small>
                                        </div>
                                        <span className="h5">&pound;  {totalSumofArray(totalPrice)} </span>
                                    </li>
                                </div>
                            }


                        </ol>
                        {localfoodData && localfoodData.length > 0 &&
                            <button type="button" className="btn btn-danger float-right mt-3" onClick={basketCheckout} >Checkout</button>
                        }
                    </div>
                }
                {displaythankyouMsg &&

                    <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">Thank You for your Order!</h4>
                        <p>Your reference No is 1234.</p>
                        <hr />
                        <p className="mb-0">We will reach out to you at the earliest.</p>
                    </div>
                }


            </div>
        </section>
    )
}