import React, { useState, useEffect } from "react";
import axios from 'axios';
import CartIcon from './images/carticon.png';
import ContainerHeading from "./components/atoms/containerheading";
import InfoCarousel from "./components/atoms/infoCarousel";
import ListIcon from './images/listview.png';
import ThumbIcon from './images/thumbview.png'



let cartObj = [];
let fData = [];
let cartStorage = (localStorage.getItem('cartdata')) ? localStorage.getItem('cartdata').split(',') : '';
let cartLen = (cartStorage) ? cartStorage.length : '';


// cartObj = (localStorage.getItem('cartdata'))?cartStorage:cartObj;


const IsotopeReact = ({ list, setList }) => {
  // init one ref to store the future isotope object

  const [foodDataObj, setFoodData] = useState(null);
  const [toggleIcon, settoggleIcon] = useState('thumbnail')
  useEffect(() => {
    if (!foodDataObj) {
      axios.get('/assets/foodData.json', {
        "Content-Type": "application/xml; charset=utf-8"
      }).then(res => {
        if (res && res.data) {
          let fooddata = res.data;
          setFoodData(fooddata)
          setList(cartLen);
          cartObj = (cartStorage.length > 0) ? cartStorage : cartObj;
        }

      });
    }
  })

  var Isotope = require('isotope-layout');
  const isotope = React.useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')

  // initialize an Isotope object with configs
  useEffect(() => {
    if (foodDataObj) {
      isotope.current = new Isotope('.filter-container', {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
      })
      // cleanup
      return () => isotope.current.destroy();
    }
  }, [Isotope, foodDataObj])

  // handling filter key change
  useEffect(() => {
    if (foodDataObj) {
      filterKey === '*'
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, foodDataObj])

  const handleFilterKeyChange = key => () => setFilterKey(key);

  const addSKUtoCart = key => () => {
    cartObj = (cartStorage.length > 0) ? cartStorage : cartObj;
    let fData = (localStorage.getItem("foodData")) ? JSON.parse(localStorage.getItem("foodData")) : [];
    if (cartObj.indexOf(key.toString()) === -1 && cartObj.indexOf(parseInt(key)) === -1) {
      foodDataObj.map(item => {
        if (parseInt(item.sku) === parseInt(key)) {
          let obj = {
            'sku': item.sku,
            'name': item.foodname,
            'price': item.price
          }
          fData.push(obj)
        }

      });

      localStorage.setItem("foodData", JSON.stringify(fData));
    }
    cartObj.push(key);
    localStorage.setItem("cartdata", cartObj);
    let len = Object.values(cartObj);
    setList(len.length);


  }

  const placeholderImage = "/assets/images/food/imgComingSoon.jpg";
  const onImageError = (e) => {
    e.target.src = placeholderImage
  }

  const toggleIconUpdate = key => () => {
    settoggleIcon(key);

  };

  return (
    <>
      <section id="food_section" className="food_section layout_padding2-top layout_padding2-bottom">
        <div className="container">
          <ContainerHeading text='Our Menu' />
          {/* <div className="toggleViewIcon" >

            {toggleIcon === 'thumbnail' &&
              <div className="listView" onClick={toggleIconUpdate('list')} title='click to view in list mode'>
                <img src={ListIcon} />
              </div>
            }
            {toggleIcon === 'list' &&
              <div className="listView" onClick={toggleIconUpdate('thumbnail')} title='click to view in list mode'>
                <img src={ThumbIcon} />
              </div>
            }

          </div> */}
          <ul className="filters_menu">
            <li className={filterKey === '*' ? "active" : ''} onClick={handleFilterKeyChange('*')}>All</li>
            <li className={filterKey === 'starter' ? "active" : ''} onClick={handleFilterKeyChange('starter')}>Starter</li>
            <li className={filterKey === 'nonveg' ? "active" : ''} onClick={handleFilterKeyChange('nonveg')}>Non Veg</li>
            <li className={filterKey === 'veg' ? "active" : ''} onClick={handleFilterKeyChange('veg')}>Veg</li>
            <li className={filterKey === 'sweets' ? "active" : ''} onClick={handleFilterKeyChange('sweets')}>Sweets</li>
          </ul>

          <div className="filter-container">
            {foodDataObj && foodDataObj.length > 0 &&
              <div className="row grid">

                {foodDataObj.map(item => {
                  let img = ('/assets/images/food/' + item['sku'] + '.jpg') ? '/assets/images/food/' + item['sku'] + '.jpg' : placeholderImage;
                  let len = cartObj.filter(x => parseInt(x) === parseInt(item.sku)).length;
                  return (
                    <div key={item.sku} className={"col-sm-2 col-lg-4 filter-item all " + item.type}>
                      <div className="box">
                        <div>
                          <div className="img-box">
                            <img src={img} alt="Fallback image" onError={onImageError} />
                          </div>
                          <div className="detail-box">
                            <h5>
                              {item.foodname}
                            </h5>
                            <p>
                              {item.description}
                            </p>
                            <div className="options">
                              <h6>
                                £{item.price}
                              </h6>
                              {/* <a href=""> */}
                              <div className="gridCart">
                                <img src={CartIcon} alt="cart" className="cartIconSmall" onClick={addSKUtoCart(item.sku)} />
                                <span className="position-absolute top-0 start-100 translate-middle rounded-pill badge text-bg-dark">{(len > 0) ? len : ''}</span>
                              </div>
                              {/* </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}


              </div>
            }

          </div>
          <div className="btn-box">
            {/* <a href="">
          View More
        </a> */}
          </div>
        </div>
      </section>
    </>
  )
}
export default IsotopeReact;