
export function cartData (){
    return localStorage.getItem('cartdata')
}

export function getImageUrl(obj) {
    return (
      obj.sku +'.png'
    );
  }

  export function backtoShopping (){
    localStorage.setItem('basketSelected','')
    window.location.reload(true);;
}

export function totalSumofArray(obj){
    let sum = '';
    if(obj && obj.length>0){
        sum = obj.reduce((accumulator, currentValue) => {return accumulator + currentValue},0);
       
    }
    return sum;
    
}