import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Markup } from 'interweave';
import Headersmall from './components/molecules/headersmall'
import Footer from './components/molecules/footer';
import axios from "axios";
// import 'https://abikitchen.dollardreamsvillas.com/php/verifyuser.php';


let jsonObj = [], fileName = '', foodStatus = 0, testimonialStatus = 0, selectedFile = '', token = '';
export default function ProductPage() {

  // axios.post('https://abikitchen.dollardreamsvillas.com/php/verifyuser.php')

  const [ImgObj, setImageData] = useState(null);
  const [msgFood, setFoodMessage] = useState();
  const [msgtestimonial, settestimonialMessage] = useState();
  const [logout, setlogout] = useState();
  let domainUrl = "https://abikitchen.dollardreamsvillas.com/";

  function convertExcelToJson(event) {
    let selectedFile = event.target.files[0];
    fileName = selectedFile.name;
    let reader = new FileReader();
    jsonObj = [];
    reader.onload = function (event) {
      var data = event.target.result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });
      //Reading Validation Excel sheet
      workbook.SheetNames.forEach(function (sheetName) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { locale: "en-US", header: 0, defval: "", raw: false, dateNF: 'dd-mm-yyyy HH:mm:ss' });
        console.log(XL_row_object);
        jsonObj = XL_row_object;
      })

    }

    reader.onerror = function (event) {

      console.error("File could not be read! Code " + event.target.error.code);
    };
    //Read upload file as binary
    reader.readAsBinaryString(selectedFile);

    reader.addEventListener("error", () => {
      console.error("Error occurred reading file");
    });
  }

  function showImageHereFunc(event) {
    var total_file = document.getElementById("files").files.length;
    selectedFile = event.target.files;
    if (total_file > 0) {
      let imgObj = []
      for (var i = 0; i < total_file; i++) {
        let htmlTxt = "<img class='foodImage card-img-top' src='" + URL.createObjectURL(event.target.files[i]) + "'>"
        imgObj.push(htmlTxt);
      }
      setImageData(imgObj)
    }

  }
  function submitForm(selectedOption) {
    if (jsonObj && jsonObj.length > 0) {
      foodStatus = 1;
      if (selectedOption === 'foodData') {
        setFoodMessage('Sending Data.....')
      }
      if (selectedOption === 'testimonial') {
        settestimonialMessage('Sending Data.....')
      }

      axios.post(domainUrl + '/php/updatedata.php', {
        'obj': jsonObj,
        'type': selectedOption,
        'token': localStorage.getItem('token'),
        'username': localStorage.getItem('user')
      })
        .then(function (response) {
          console.log(response);
          if (response.data.result === 'success') {
            setlogout('')
            if (selectedOption === 'foodData') {
              setFoodMessage(fileName + ' uploaded successfully!!');
              foodStatus = 0;
            }
            if (selectedOption === 'testimonial') {
              settestimonialMessage(fileName + ' uploaded successfully!!');
              testimonialStatus = 0;
            }
          } else {
            setlogout('fail');
           
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }
  function uploadImages() {
    //uploadMultipleImages.php
    if (ImgObj && ImgObj.length > 0) {
      const data = new FormData();

      for (let i = 0; i < selectedFile.length; i++) {
        data.append("files[]", selectedFile[i]);
      }
      let url = domainUrl + "php/uploadMultipleImages.php";

      axios
        .post(url, data, {
          // receive two parameter endpoint url ,form data
        })
        .then((res) => {
          // then print response status
          console.log(res.data);

        }, error => {
          alert(error);
        });
    }
  }
  return (
    <div className='adminPanel'>
      <Headersmall />
      <div className='container'>
        <div className='wrapper'></div>
        <div className=' updateServerData layout_padding3'>
          <div className='row'>
            <div className="col-md-6">
              <h2 className='text-center mb-5'>Food</h2>
              <label htmlFor="formFile" className="form-label">Upload Food File</label>
              <input className="form-control" type="file" onChange={convertExcelToJson} id="formFile" />
              {msgFood && <div>{msgFood}</div>}
              <button type="button" className="btn btn-danger mt-4" onClick={() => { submitForm('foodData') }} >
                {msgFood && foodStatus === 1 && <span className="spinner-border text-warning" role="status"></span>
                }
                {foodStatus === 0 && <span>Submit Food</span>}
              </button>


            </div>
            <div className='col-md-2 float-start col-sm-12  OrPartition'>
              <span className='formdivider'></span>
            </div>
            <div className="col-md-4 testimonialSection">
              <h2 className='text-center mb-5'>Testimonial</h2>
              <label htmlFor="formFile" className="form-label">Upload Testimonial File</label>
              <input className="form-control" type="file" onChange={convertExcelToJson} id="formFile" />
              {msgtestimonial && <div>{msgtestimonial}</div>}
              <button type="button" className="btn btn-danger mt-4" onClick={() => { submitForm('testimonial') }} >
                {msgtestimonial && testimonialStatus === 1 && <span className="spinner-border text-warning" role="status"></span>
                }
                {testimonialStatus === 0 && <span>Submit Testimonial</span>}
              </button>
            </div>
          </div>
          <div className="row">
            <div className='col-md-2 float-start col-sm-12  OrPartition onlyForMobile'>
              <span className='formdivider'></span>
            </div>
            <div className="container mt-5">
              <h2 className='text-center mb-5 mt-40-mobile'>Upload Image</h2>
              <form action="create_photo_gallery.php" method="POST" enctype="multipart/form-data" id="imageInputForm">
                <label htmlFor="formFile" className="form-label">Upload Testimonial File</label>
                <input className="form-control" name="files[]" multiple type="file" onChange={showImageHereFunc} id="files" />
                <button type="button" className="btn btn-danger mt-4" onClick={uploadImages} >Upload Images</button>

              </form>
              <div className="user-image mb-3 text-center">
                <div id="showImageHere">
                  <div className="card-group">
                    <div className="row">
                      {ImgObj && ImgObj.length > 0 &&
                        ImgObj.map(item => {
                          return (<Markup content={item} />)
                        })

                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {logout &&
        <div className="infobox">
          <div class="modal" id='myModal' tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {/* <h5 class="modal-title">Modal title</h5> */}
                  {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div class="modal-body">
                  <p>Available Token not matched. Please login again to upload files.</p>
                </div>
                {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="wrapper d-block"></div>
          </div>
        }
      </div>
      <Footer /></div>
  );



}