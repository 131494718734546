import React, { useState, useEffect } from "react";
import axios from 'axios';
import Carousel from 'bootstrap/js/dist/carousel'
// import * as fs from 'file-saver';
export default function Testimonial() {
    const [testimonialObj, setTestimonialData] = useState(null);

    // const myCarouselElement = document.querySelector('#carouselExampleAutoplaying');
    // const carousel = new bootstrap.Carousel(myCarouselElement, {
    //     interval: 2000,
    //     touch: false
    //   })
    useEffect(() => {
        if (!testimonialObj) {
            axios.get('/assets/testimonial.json', {
                "Content-Type": "application/xml; charset=utf-8"
            }).then(res => {
                if (res && res.data) {
                    let fooddata = res.data;
                    setTestimonialData(fooddata);
                    const carousel = new Carousel('#carouselExampleAutoplaying')

                }

            });
        }
    })
    return (

        <section className="client_section layout_padding-bottom" id='client_section'>
            <div className="container">
                <div className="heading_container heading_center psudo_white_primary mb_45">
                    <h2>
                        What Says Our Customers
                    </h2>
                </div>
                <div id="carouselExampleAutoplaying" className="carousel carousel-dark slide" data-bs-ride="carousel">

                    <div className="carousel-inner text-center">

                        {testimonialObj && testimonialObj.length > 0 &&
                            testimonialObj.map((item, key) => {
                                return (<div key={key} className={key === 0 ? "carousel-item active" : "carousel-item"}>
                                    <div className="col-8 customCommentBlock alert d-block alert-primary">
                                        <q className="d-block w-100">{item.comment}</q>
                                        <h4 className="text-end dancingFont .text-decoration-underline mt-3"><strong>{item.name}</strong></h4>
                                    </div>
                                </div>)
                            })
                        }



                    </div>
                    {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>


            </div>
        </section>


    )
}