import CartIcon from './../../images/carticon.png'
export default function Header({list}) {
  
 const basketselected = key => () => {
  localStorage.setItem('basketSelected','true')
  window.location.reload(true);;
 }
 
 let isBasketSelected = localStorage.getItem("basketSelected")
  return (
    <div className={(isBasketSelected)?"hero_area basketContainer":"hero_area"}>
      
      <div className="bg-box">
        <img src={require('../../images/banner.jpg')} alt="banner" />

      </div>
        <header className="header_section">
        <div className="container">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <a className="navbar-brand" href="/">
              <img src={require('../../images/logo.png')} alt="logo" className="mainlogo" />

            </a>

            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className=""> </span>
            </button> */}

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav  mx-auto ">
                <li className="nav-item active">
                  <a className="nav-link" href="/">Home </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#food_section">Menu</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#client_section">Testimonial</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactus">Contact</a>
                </li>
              
              </ul>
            
            </div>
            <div className="user_option">
             
             <div className='topCart'>

               <button type="button" className="btn p-0 position-relative" onClick={basketselected()}>
                 <img src={CartIcon} alt="cart" className="cartIconSmall" />
                 <span className="position-absolute top-0 start-100 translate-middle rounded-pill badge text-bg-dark">
                 {list}
              
                 </span>
               </button>
             </div>


           </div>
          </nav>
        </div>
      </header>
      <section className="slider_section ">
        <div className="container ">
          <div className="row">
            <div className="col-md-7 col-lg-6 ">
              <div className="detail-box">
                <h1>
                  Authentic Homemade Indian Food
                </h1>
                <p>
                  Homemade food is usually prepared wth natural ingredients compared to commercially prepared food.
                </p>
                <div className="btn-box">
                  <a href="#food_section" className="btn1">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )

}