export default function Footer() {
    return (
        <div>
            <footer className="footer_section">
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 footer-col">
                                <div className="footer_contact" id='contactus'>
                                    <h4>
                                        Contact Us
                                    </h4>
                                    <div className="contact_link_box">
                                        <a href="">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <span>
                                                Location
                                            </span>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                            <span>
                                                Call +44 7466404414
                                            </span>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                            <span>
                                                Abisha.sam@gmail.com
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 footer-col">
                                <div className="footer_detail">
                                    <a href="" className="footer-logo">
                                        Abi's Kitchen
                                    </a>
                                    <p>
                                        Authentic Home made Indian food - We cook and deliver simple and tasty food using traditional Indian recipes and high-quality ingredients only. Everything is made from scratch fresh once you preorder.

                                    </p>

                                </div>
                            </div>
                            <div className="col-md-4 footer-col">
                                <h4>
                                    Opening Hours
                                </h4>
                                <p>
                                    Everyday 
                                </p>
                                <p>( Only on Preorder - Minimum one day in advance) with minimum of 4 Plates of any order.</p>

                            </div>
                        </div>
                        <div className="footer-info">
                            <div>
                                &copy; <span id="displayYear"></span> All Rights Reserved By
                                <a href="https://html.design/">Free Html Templates</a><br /><br />
                                &copy; <span id="displayYear"></span> Distributed By
                                <a href="https://themewagon.com/" target="_blank">ThemeWagon</a>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}