
export default function Headersmall({ }) {

    return (
        <div className="hero_area">

            {/* <div className="bg-box">
                <img src={require('../../images/bannersmall.jpg')} alt="banner" />
            </div> */}
            <header className="header_section">
                <div className="container">
                    <nav className="navbar navbar-expand-lg custom_nav-container ">
                        <a className="navbar-brand" href="/">
                            <img src={require('../../images/logo.png')} alt="logo" className="mainlogo" />

                        </a>
                    </nav>
                </div>
            </header>
          
        </div>

    )

}