import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import App from './App';
import UpdateData from './updateData';
import reportWebVitals from './reportWebVitals';
import Login from './login';

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/home" element={<App />} />
    <Route path="/updatedata" element={<UpdateData />} />
    <Route path="/login" element={<Login />} />
  </Routes>
  </BrowserRouter>
  // <React.StrictMode>
  //   {/* <BrowserRouter> */}
  //   <App />
  //   {/* </BrowserRouter> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();