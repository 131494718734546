import {useState} from 'react';

import Header from './components/molecules/header';
import Footer from './components/molecules/footer';
import IsotopeReact from './menu'
import Basket from './basket';
import Testimonial from './testimonial';



import './style/GlobalStyle.css';
import './style/responsive.css';
import './js/GlobalScript.js';

function App() {
  const [list, setList] = useState([])
  return (
    <div className="App">
      <Header list = {list}/>
      <Basket />
      <IsotopeReact list = {list}  setList = {setList}/>
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
