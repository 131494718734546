import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
let domainUrl = "https://abikitchen.dollardreamsvillas.com/";
let user = '';
export default function Login() {
    const [msg, setMessage] = useState();
    const navigate = useNavigate();
    function submitForm(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        let url = domainUrl + "php/login.php";
        data.set('username', data.get('username'));
        data.set('password', data.get('password'));
        user = data.get('username');
        axios.post(url, data, {
            // receive two parameter endpoint url ,form data
        })
            .then((res) => {
                // console.log(res.data);
                let response = (res.data) ? res.data.result : '';
                if (response) {
                    if (response.status !== 'success') {
                        setMessage(response.status);
                    } else {
                        setMessage('');
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('user', user);
                        navigate("/updatedata");
                    }
                }

            }, error => {
                alert(error);
            });

    }

    return (
        <section className="h-100 gradient-form">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-10">
                        <div className="card rounded-3 text-black">
                            <div className="row g-0">
                                <div className="col-lg-6">
                                    <div className="card-body p-md-5 mx-md-4">

                                        <div className="text-center">
                                            <img src={require('./images/logo.png')}
                                                className="loginlogo" alt="logo" />
                                            {/* <h4 className="mt-1 mb-5 pb-1">We are The Lotus Team</h4> */}
                                        </div>
                                        {msg &&
                                            <div class="alert alert-danger" role="alert">{msg}</div>
                                        }


                                        <form onSubmit={submitForm} method="POST" action="#javascript">
                                            {/* <p>Please login to your account</p> */}

                                            <div className="form-outline mb-4 mt-4">
                                                <label className="form-label" htmlFor="form2Example11">Username</label>
                                                <input type="text" name='username' id="form2Example11" className="form-control"
                                                />

                                            </div>

                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="form2Example22">Password</label>
                                                <input type="password" name='password' id="form2Example22" className="form-control" />

                                            </div>

                                            <div className="text-center pt-1 mb-5 pb-1">
                                                <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="submit">Log
                                                    in</button>

                                            </div>



                                        </form>

                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                                        <h4 className="mb-4">We are more than just a company</h4>
                                        <p className="small mb-0">We cook and deliver simple and tasty food using traditional Indian recipes and high-quality ingredients only. Everything is made from scratch fresh once you preorder.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}